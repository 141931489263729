import { handleErrorWithSentry } from "@sentry/sveltekit";
import * as Sentry from "@sentry/sveltekit";

const dsn =
  process.env.NODE_ENV === undefined || process.env.NODE_ENV === "local"
    ? ""
    : "https://8aff1d0e6188607a209843bbe73aa685@o4508047236005888.ingest.de.sentry.io/4508132156899408";

Sentry.init({
  dsn: dsn,

  tracesSampleRate: 1.0,

  // This sets the sample rate to be 10%. You may want this to be 100% while
  // in development and sample at a lower rate in production
  replaysSessionSampleRate: 0,

  // If the entire session is not sampled, use the below sample rate to sample
  // sessions when an error occurs.
  replaysOnErrorSampleRate: 0,

  // If you don't want to use Session Replay, just remove the line below:
  environment: process.env.NODE_ENV,
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
